import { Checkbox, DatePicker, Form, Input, InputNumber } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import intl from 'react-intl-universal';
import LovSelect from '../../lov/ui/LovSelect';
import { DATE_FORMAT, TIME_FORMAT } from '../../shell/shellConstants';
import ConstantRadio from './ConstantRadio';
import ConstantSelect from './ConstantSelect';

const { TextArea } = Input;

export default function FieldMetaEditingCell({
  fieldMeta,
  valueArrayIndex,
  formItemIdSuffix,
  liveFormRefs
}) {

  // defaults
  let input = <Input
    disabled={shouldDisable(fieldMeta)}
    allowClear={!fieldMeta.required}
  />;

  let initialValue = valueArrayIndex === undefined
    ? fieldMeta.value
    : (fieldMeta.values && fieldMeta.values.length > valueArrayIndex)
      ? fieldMeta.values[valueArrayIndex]
      : null;

  let valuePropName = 'value';

  // depends
  if (!fieldMeta.viewable) {
    // override component type
    input = <Input
      type="password"
      disabled
    />;
    // marked value
    initialValue = "----";
  } else if ('C' === fieldMeta.componentType) {
    input = <Checkbox disabled={shouldDisable(fieldMeta)}>
      {(!valueArrayIndex) && fieldMeta.fieldLabel}
    </Checkbox>;
    // convert value
    initialValue = 'Y' === fieldMeta.value;
    // convert property name
    valuePropName = 'checked';
  } else if ('I' === fieldMeta.componentType) {
    input = <Input
      disabled={shouldDisable(fieldMeta)}
      allowClear={!fieldMeta.required}
    />;
  } else if ('T' === fieldMeta.componentType) {
    input = <TextArea
      autoSize={{ minRows: fieldMeta.dgH || 1 }} // might strech UI super large 
      // rows={fieldMeta.dgH || 1}
      disabled={shouldDisable(fieldMeta)}
    />;
  } else if ('N' === fieldMeta.componentType) {
    input = <InputNumber
      disabled={shouldDisable(fieldMeta)}
      style={{ width: '100%' }}
      precision={2}
      max={fieldMeta.maxValue || Number.MAX_SAFE_INTEGER}
      min={fieldMeta.minValue || Number.MIN_SAFE_INTEGER}
    // step={0.01}
    />;
    // convert value
    initialValue = initialValue
      ? numeral(initialValue).value()
      : initialValue;
  } else if ('D' === fieldMeta.componentType
    || 'M' === fieldMeta.componentType) {
    const format = 'D' === fieldMeta.componentType
      ? DATE_FORMAT
      : TIME_FORMAT;
    input = <DatePicker
      showTime={format === TIME_FORMAT}
      format={format}
      style={{ width: '100%' }}
      disabled={shouldDisable(fieldMeta)}
      allowClear={!fieldMeta.required}
    />;
    // convert value
    initialValue = initialValue
      ? moment(initialValue, format)
      : initialValue;
  } else if ('R' === fieldMeta.componentType) {
    input = <ConstantRadio
      fieldMeta={fieldMeta}
      disabled={shouldDisable(fieldMeta)}
    />
  } else if ('S' === fieldMeta.componentType) {
    input = <ConstantSelect
      fieldMeta={fieldMeta}
      disabled={shouldDisable(fieldMeta)}
      allowClear={!fieldMeta.required}
    />;
  } else if ('L' === fieldMeta.componentType) {
    input = <LovSelect
      fieldMeta={fieldMeta}
      disabled={shouldDisable(fieldMeta)}
      allowClear={!fieldMeta.required}
      liveFormRefs={liveFormRefs}
    />;
  }

  // rules
  const rules = [];
  if (fieldMeta.editable) {
    if ('N' === fieldMeta.componentType
      && (fieldMeta.minValue !== null
        || fieldMeta.maxValue !== null)) {
      // apply required rule
      rules.push({
        required: true
      });
      // apply range validator
      rules.push({
        validator: async (rule, value) => validateRange(fieldMeta, value)
      });
    } else if (fieldMeta.required) {
      // apply required rule only
      rules.push({
        required: true
      });
    }
  }

  // create form item
  const id = fieldMeta.recKey + (
    valueArrayIndex === undefined
      ? '' // to make id a string
      : ('_' + (
        // differ by suffix
        formItemIdSuffix
        // fall back to row index
        || valueArrayIndex
      ))
  );
  const formItem = (
    <Form.Item
      name={id}
      initialValue={initialValue}
      valuePropName={valuePropName}
      rules={rules}
      style={{ margin: 0 }}
    >
      {input}
    </Form.Item>
  );

  return formItem;
}

function shouldDisable(fieldMeta) {
  return !fieldMeta.viewable || !fieldMeta.editable;
}

function validateRange(fieldMeta, value) {
  if (fieldMeta.minValue
    && value < numeral(fieldMeta.minValue).value()) {
    // report
    throw new Error(
      intl.get('FieldMetaEditingCell.string.minValue')
        .d('Min Value')
      + ': '
      + fieldMeta.minValue
    );
  } else if (fieldMeta.maxValue
    && value > numeral(fieldMeta.maxValue).value()) {
    // report
    throw new Error(
      intl.get('FieldMetaEditingCell.string.maxValue')
        .d('Max Value')
      + ': '
      + fieldMeta.maxValue
    );
  }
}